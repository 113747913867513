<!-- prettier-ignore -->
<script setup>
import { useI18n } from 'vue-i18n'

// Dependencies
import * as yup from 'yup'

// Configuration
const config = useRuntimeConfig()

// Router
const route = useRoute()
const router = useRouter()

// Events
const emit = defineEmits(['onClose'])

// i18n
const { t } = useI18n()

// Sentry
const { $sentry } = useNuxtApp()

// Variables
const birthdayMin = new Date(new Date().setFullYear(new Date().getFullYear() - 125))
const birthdayMax = new Date(new Date().setFullYear(new Date().getFullYear() - 18))

// Form validation schema
const schema = yup.object({
  // Email Address
  email: yup
    .string()
    .email(t('form.errors.emailInvalid'))
    .required(t('form.errors.emailRequired')),

  // Terms & Condidition
  terms: yup
    .boolean()
    .test('is-true', t('form.errors.termsAccept'), (value) => !!value),

  // Gender
  gender: yup
    .string()
    .required(t('form.errors.genderRequired')),

  // Privacy Policy
  privacy: yup
    .boolean()
    .test('is-true', t('form.errors.privacyAccept'), (value) => !!value),

  // Research Type
  payload: yup
    .string()
    .required(t('form.errors.payloadRequired')),

  // Birthday Date
  birthday: yup
    .date()
    .required(t('form.errors.birthdayRequired'))
    .nullable()
    .default(undefined)
    .min(birthdayMin, t('form.errors.birthdayMinimum'))
    .max(birthdayMax, t('form.errors.birthdayMaximum')),

  // Last Name
  lastName: yup
    .string()
    .required(t('form.errors.lastNameRequired'))
    .min(2, t('form.errors.lastNameMinimum')),

  // First Name
  firstName: yup
    .string()
    .required(t('form.errors.firstNameRequired'))
    .min(2, t('form.errors.firstNameMinimum')),

  // Postal Code
  postalCode: yup
    .string()
    .required(t('form.errors.postalCodeRequired')),

  // Nationality
  nationality: yup
    .string()
    .required(t('form.errors.nationalityRequired')),

  // Space Experience
  spaceExperience: yup
    .string()
    .required(t('form.errors.spaceExperienceRequired')),

  // Flight Availability
  flightAvailability: yup
    .string()
    .required(t('form.errors.flightAvailabilityRequired')),

  // Research Field
  researchField: yup
    .string()
    .required(t('form.errors.researchFieldRequired')),

  // Research Description
  researchDescription: yup
    .string()
    .when(['payload', 'flightAvailability'], {
      is: (payload, flightAvailability) => payload === 'uncertain' && flightAvailability === 'uncertain',
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required(t('form.errors.researchDescriptionRequired')).min(10, t('form.errors.researchDescriptionMinimum')),
    }),

  // Research Organization
  researchOrganization: yup
    .string()
    .required(t('form.errors.researchOrganizationRequired'))
    .min(2, t('form.errors.researchOrganizationMinimum')),

  // Organization Project Role
  organizationProjectRole: yup
    .string()
    .required(t('form.errors.organizationProjectRoleRequired'))
    .min(2, t('form.errors.organizationProjectRoleMinimum')),
})

// Fields by steps
const screenFields = {
  0: [
    'email',
    'terms',
    'lastName',
    'firstName',
  ],
  1: [],
  2: {
    updates: [
      'gender',
      'birthday',
      'nationality',
    ],
    investors: [
      'gender',
      'birthday',
      'nationality',
    ],
    waitlist: [
      'phone',
      'gender',
      'birthday',
      'privacy',
      'postalCode',
      'nationality',
      'flightAvailability',
    ],
    research: [
      'privacy',
      'organizationPhone',
      'organizationProjectRole',
      'researchOrganization',
    ],
  },
  3: [
    'payload',
    'researchField',
    'spaceExperience',
    'flightAvailability',
  ],
  4: [
    'researchDescription',
  ]
}

// References
const screen = ref(0)
const interest = ref('updates')
const disclaimer = ref(true)
const isSubmitted = ref(false)
const isSubmitting = ref(false)
const researchDescriptionRequired = ref(true)
// -- DOM Elements
const phone = ref(null)
const organizationPhone = ref(null)

// Computed values
const screenCount = computed(() => {
  switch (interest.value) {
    case 'research': {
      return 5
    }

    default: {
      return 3
    }
  }
})

const screenIndexFormatted = computed(() => (screen.value + 1).toString().padStart(2, 0))
const screenCountFormatted = computed(() => screenCount.value.toString().padStart(2, 0))

const screenActiveFields = computed(() => {
  let entries = []

  switch (screen.value) {
    case 2: {
      entries = screenFields[screen.value][interest.value]
      break
    }

    default: {
      entries = screenFields[screen.value]
    }
  }

  return entries
})

const screenDisclaimer = computed(() => {
  if (screen.value >= 2) {
    if (isSubmitting.value || isSubmitted.value) {
      return false
    }

    return interest.value === 'research'
  }

  return false
})

// Options
const payloadOptions = [
  { label: t('form.payloadOptions["autonomous"]'), value: 'Autonomous payload' },
  { label: t('form.payloadOptions["human-tended"]'), value: 'Human-tended payload' },
  { label: t('form.payloadOptions["uncertain"]'), value: 'Undecided' },
]

const genderOptions = [
  { label: t('form.genderOptions["male"]'), value: 'male' },
  { label: t('form.genderOptions["female"]'), value: 'female' },
  { label: t('form.genderOptions["non-binary"]'), value: 'non-binary' },
  { label: t('form.genderOptions["Prefer not to answer"]'), value: 'no-answer' },
]

const flightAvailabilityOptions = [
  { label: t('form.flightAvailabilityOptions["1-2"]'), value: '1 - 2 years' },
  { label: t('form.flightAvailabilityOptions["5"]'), value: '5 years' },
  { label: t('form.flightAvailabilityOptions["uncertain"]'), value: 'Uncertain' },
]

const spaceExperienceOptions = [
  { label: t('form.spaceExperienceOptions["yes"]'), value: 'yes' },
  { label: t('form.spaceExperienceOptions["no"]'), value: 'no' },
]

// Fields Defaults
const fieldsDefaults = {
  email: '',
  phone: '',
  terms: false,
  gender: '',
  privacy: false,
  payload: '',
  birthday: undefined,
  lastName: '',
  firstName: '',
  postalCode: '',
  nationality: '',
  spaceExperience: '',
  flightAvailability: '',

  researchField: '',
  researchDescription: '',
  researchOrganization: '',

  organizationPhone: '',
  organizationProjectRole: '',
}

// Errors Defaults
const errorsDefaults = {
  email: '',
  phone: '',
  terms: '',
  gender: '',
  privacy: '',
  payload: '',
  birthday: '',
  lastName: '',
  firstName: '',
  postalCode: '',
  nationality: '',
  spaceExperience: '',
  flightAvailability: '',

  researchField: '',
  researchDescription: '',
  researchOrganization: '',

  organizationPhone: '',
  organizationProjectRole: '',
}

// Fields & Errors States
const fields = reactive({ ...fieldsDefaults })
const errors = reactive({ ...errorsDefaults })

// Validation State
const validations = reactive({
  email: false,
  phone: false,
  terms: false,
  gender: false,
  privacy: false,
  payload: false,
  birthday: false,
  lastName: false,
  firstName: false,
  postalCode: false,
  nationality: false,
  spaceExperience: false,
  flightAvailability: false,

  researchField: false,
  researchDescription: false,
  researchOrganization: false,

  organizationPhone: false,
  organizationProjectRole: false,
})

// Watchers
watch(fields, (newValue) => {
  researchDescriptionRequired.value = !(
    newValue.payload === 'uncertain' &&
    newValue.flightAvailability === 'uncertain'
  )
})

// Functions
const getSalesforcePayload = () => {
  // Mappings
  // -- Fields
  const fieldsMapping = {
    email: 'Email',
    phone: 'MobilePhone',
    terms: 'Privacy_Policy__c',
    privacy: 'Phone_opt_in__c',
    gender: 'Gender__c',
    payload: 'Interest__c',
    birthday: 'Date_of_Birth__c',
    lastName: 'LastName',
    firstName: 'FirstName',
    postalCode: 'PostalCode',
    nationality: 'Nationality__c',
    spaceExperience: 'Space_Experience__c',
    flightAvailability: 'When_will_you_be_ready_to_buy__c',

    researchField: 'Research_field__c',
    researchDescription: 'Research_Description__c',
    researchOrganization: 'Company',

    organizationPhone: 'Phone',
    organizationProjectRole: 'Project_Role__c',
  }

  // -- Interests
  const interestsMapping = {
    updates: 'Virgin_Galactic_Updates__c',
    waitlist: 'Interested_in_future_spaceflight__c',
    research: 'Research_flights_and_payloads__c',
    investors: 'Investor_Enquiry__c',
  }

  // Get fields to send
  let fieldsToSend = [
    ...screenFields[0],
    ...screenFields[1],
    ...screenFields[2][interest.value],
  ]

  if (interest.value === 'research') {
    fieldsToSend = [
      ...fieldsToSend,
      ...screenFields[3],
      ...screenFields[4],
    ]
  }

  // Build payload
  const payload = {}

  payload.RecordTypeId = '0123z000000Z5qZAAS'
  payload.Lead_Category__c = 'Brand Fan'

  // -- Add interest
  payload[interestsMapping[interest.value]] = 1

  // -- Add fields
  for (const field of fieldsToSend) {
    payload[fieldsMapping[field]] = fields[field]
  }

  return payload
}

// Handlers
// -- UI Handlers
const handleNext = async () => {
  // Form validation
  // Global error state
  let hasErrors = false

  // Fields to validate
  const actives = screenActiveFields.value

  for (const field of actives) {
    if (!validations[field]) {
      let el = null

      switch(field) {
        case 'phone': {
          el = phone.value
          break
        }

        case 'organizationPhone': {
          el = organizationPhone.value
          break
        }
      }

      // Get validation result
      const res = await (el?.handleFieldValidation ? el.handleFieldValidation() : handleFieldValidation(field))

      if (!res.valid) {
        hasErrors = true
      }
    }
  }

  // Global errors verification
  if (hasErrors) {
    return
  }

  // Submission process
  if (screen.value < screenCount.value - 1) {
    screen.value = Math.min(screen.value + 1, screenCount.value)
  } else {
    // Change states
    isSubmitted.value = false
    isSubmitting.value = true

    // Build Salesforce payload
    const salesforcePayload = getSalesforcePayload()

    // Post to Salesforce
    const response = await fetch(config.LEAD_INTAKE_ENDPOINT, {
      body: JSON.stringify(salesforcePayload),
      method: 'POST',
    })

    // Error handling
    if (response.status !== 200) {
      $sentry.captureException(response)
      console.error(response)
    }

    // Reset states
    isSubmitted.value = true
    isSubmitting.value = false

    // Reset all fields
    Object.keys(fields).forEach((field) => {
      // Reset fields
      fields[field] = fieldsDefaults[field]

      // Reset errors
      errors[field] = errorsDefaults[field]

      // Reset validations
      validations[field] = false
    })
  }
}

const handleFinish = () => {
  if (route.name.startsWith('sign-up')) {
    // redirect to homepage
    router.push('/')
  } else {
    emit('onClose')
  }

  // Reset screen
  screen.value = 0

  // Reset interest
  interest.value = 'updates'

  // Reset disclaimer
  disclaimer.value = true

  // Reset states
  isSubmitted.value = false
  isSubmitting.value = false
}

const handlePrevious = () => {
  // Reset screen fields
  const actives = screenActiveFields.value

  for (const field of actives) {
    // Reset fields
    fields[field] = fieldsDefaults[field]

    // Reset errors
    errors[field] = errorsDefaults[field]

    // Reset validations
    validations[field] = false
  }

  // Reset disclaimer
  if (screen.value === 2) {
    disclaimer.value = true
  }

  // Change screen value
  screen.value = Math.max(screen.value - 1, 0)
}

const handleDisclaimer = () => {
  disclaimer.value = !disclaimer.value
}

// -- Fields Handlers
const handleFieldChange = async (field, value, validate = true) => {
  fields[field] = value

  if (validate) {
    await handleFieldValidation(field)
  }
}

const handleFieldValidation = async (field) => {
  // Make it invalid in the `validations` object
  validations[field] = false

  try {
    // Validate field
    await schema.validateAt(field, fields)

    // Field valid
    // Reset field error to its default value
    errors[field] = errorsDefaults[field]

    // Make it valid in the `validations` object
    validations[field] = true
  } catch (error) {
    // Field invalid
    // Change its error message
    if (error) {
      errors[field] = error.message
    }
  }

  return {
    error: errors[field],
    valid: validations[field],
  }
}

const handleCustomValidation = (field, payload) => {
  // Inherit errors from custom component
  errors[field] = payload.error

  // Inherit validations from custom component
  validations[field] = payload.valid
}
</script>

<template>
  <div class="form">
    <div v-if="!isSubmitted" class="form__steps">
      <div class="form__left" :class="`form__left--${interest}`">
        <div class="form__left-texts">
          <!-- <h2 class="form__left-headline text__headline-2">
            <span>{{ $t('form.primaryHeadline') }}</span>
            <span v-if="fields.firstName">, {{ fields.firstName }}</span>
          </h2>

          <p
            class="form__left-description"
            v-html="$preventOrphans($t('form.subtitle1'))"
          /> -->
        </div>
      </div>

      <div class="form__right">
        <div class="form__right-container">
          <p class="form__progress">
            <b>{{ screenIndexFormatted }}</b> – {{ screenCountFormatted }}
          </p>

          <div class="form__divider" />

          <template v-if="screen === 0">
            <!-- Step 1 -->
            <div class="form__form">
              <h3
                class="form__form-title text--gradient"
                v-html="$t('form.headline1')"
              />

              <p class="form__description">{{ $t('form.description') }}</p>

              <div class="form__form-row">
                <input
                  type="text"
                  required
                  :class="['input__text', { 'has-error': errors.firstName }]"
                  :placeholder="`${$t('form.fields.firstName')}*`"
                  @blur="handleFieldValidation('firstName')"
                  @input="handleFieldValidation('firstName')"
                  v-model="fields.firstName"
                />

                <p v-if="errors.firstName" class="input__error">
                  {{ errors.firstName }}
                </p>
              </div>

              <div class="form__form-row">
                <input
                  type="text"
                  required
                  :class="['input__text', { 'has-error': errors.lastName }]"
                  :placeholder="`${$t('form.fields.lastName')}*`"
                  @blur="handleFieldValidation('lastName')"
                  @input="handleFieldValidation('lastName')"
                  v-model="fields.lastName"
                />

                <p v-if="errors.lastName" class="input__error">
                  {{ errors.lastName }}
                </p>
              </div>

              <div class="form__form-row">
                <input
                  type="email"
                  required
                  :class="['input__text', { 'has-error': errors.email }]"
                  :placeholder="`${$t('form.fields.email')}*`"
                  @blur="handleFieldValidation('email')"
                  @input="handleFieldValidation('email')"
                  v-model="fields.email"
                />

                <p v-if="errors.email" class="input__error">
                  {{ errors.email }}
                </p>
              </div>

              <div class="form__form-footer">
                <input
                  id="form-terms"
                  type="checkbox"
                  required
                  :class="['input__checkbox', { 'has-error': errors.terms }]"
                  @change="handleFieldValidation('terms')"
                  v-model="fields.terms"
                />

                <label for="form-terms">
                  <span>
                    {{ $t('form.toc1') }}
                    <NuxtLink target="_blank" to="/privacy-policy">
                      {{ $t('form.toc2') }}
                    </NuxtLink>
                    {{ $t('form.toc3') }}
                    <NuxtLink target="_blank" to="/terms-and-conditions">
                      {{ $t('form.toc4') }}
                    </NuxtLink>
                  </span>
                </label>

                <p v-if="errors.terms" class="input__error">
                  {{ errors.terms }}
                </p>
              </div>
            </div>
          </template>

          <template v-if="screen === 1">
            <!-- Step 2 -->
            <div class="form__form">
              <h3 class="form__form-title text--gradient">
                {{ $t('form.headline2') }}
              </h3>

              <div class="form__form-grid">
                <div class="form__form-cell">
                  <input
                    id="form-interest-1"
                    type="radio"
                    name="interest"
                    value="updates"
                    class="input__radio"
                    v-model="interest"
                  />

                  <label for="form-interest-1">
                    {{ $t('form.interest1') }}
                  </label>
                </div>

                <div class="form__form-cell">
                  <input
                    id="form-interest-2"
                    type="radio"
                    name="interest"
                    value="waitlist"
                    class="input__radio"
                    v-model="interest"
                  />

                  <label for="form-interest-2">
                    {{ $t('form.interest2') }}
                  </label>
                </div>

                <div class="form__form-cell">
                  <input
                    id="form-interest-3"
                    type="radio"
                    name="interest"
                    value="research"
                    class="input__radio"
                    v-model="interest"
                  />

                  <label for="form-interest-3">
                    {{ $t('form.interest3') }}
                  </label>
                </div>

                <div class="form__form-cell">
                  <input
                    id="form-interest-4"
                    type="radio"
                    name="interest"
                    value="investors"
                    class="input__radio"
                    v-model="interest"
                  />

                  <label for="form-interest-4">
                    {{ $t('form.interest4') }}
                  </label>
                </div>
              </div>
            </div>
          </template>

          <template v-if="screen === 2">
            <!-- Step 3 -->
            <div class="form__form">
              <h3 class="form__form-title text--gradient">
                {{ $t('form.headline3') }}
              </h3>

              <template v-if="interest === 'waitlist'">
                <div class="form__form-row">
                  <InputPhone
                    ref="phone"
                    name="phone"
                    placeholder="(123) 456-7890*"
                    @custom-validation="handleCustomValidation"
                    v-model="fields.phone"
                  />

                  <p v-if="errors.phone" class="input__error">
                    {{ errors.phone }}
                  </p>
                </div>
              </template>

              <template v-if="interest !== 'research'">
                <div class="form__form-row">
                  <InputDate
                    name="birthday"
                    :error="errors.birthday"
                    :placeholder="$t('form.fields.birthday')"
                    @custom-blur="handleFieldValidation('birthday')"
                    @custom-change="handleFieldChange"
                  />

                  <p v-if="errors.birthday" class="input__error">
                    {{ errors.birthday }}
                  </p>
                </div>

                <div class="form__form-row">
                  <InputSelect
                    class="input__select"
                    :class="{ 'has-error': errors.gender }"
                    :label="`${$t('form.fields.gender')}*`"
                    :options="genderOptions"
                    required
                    @custom-blur="handleFieldValidation('gender')"
                    @custom-change="handleFieldValidation('gender')"
                    v-model="fields.gender"
                  />

                  <p v-if="errors.gender" class="input__error">
                    {{ errors.gender }}
                  </p>
                </div>

                <div class="form__form-row">
                  <InputNationality
                    :error="errors.nationality"
                    :selected="fields.nationality"
                    @custom-blur="handleFieldValidation('nationality')"
                    @custom-change="handleFieldChange"
                  />

                  <p v-if="errors.nationality" class="input__error">
                    {{ errors.nationality }}
                  </p>
                </div>
              </template>

              <template v-if="interest === 'waitlist'">
                <div class="form__form-row">
                  <input
                    type="text"
                    required
                    :class="['input__text', { 'has-error': errors.postalCode }]"
                    :placeholder="`${$t('form.fields.postalCode')}*`"
                    @blur="handleFieldValidation('postalCode')"
                    @input="handleFieldValidation('postalCode')"
                    v-model="fields.postalCode"
                  />

                  <p v-if="errors.postalCode" class="input__error">
                    {{ errors.postalCode }}
                  </p>
                </div>

                <div class="form__form-row">
                  <InputSelect
                    class="input__select"
                    :class="{ 'has-error': errors.flightAvailability }"
                    :label="`${$t('form.fields.flightAvailability')}*`"
                    :options="flightAvailabilityOptions"
                    required
                    @custom-blur="handleFieldValidation('flightAvailability')"
                    @custom-change="handleFieldValidation('flightAvailability')"
                    v-model="fields.flightAvailability"
                  />

                  <p v-if="errors.flightAvailability" class="input__error">
                    {{ errors.flightAvailability }}
                  </p>
                </div>
              </template>

              <template v-if="interest === 'research'">
                <div class="form__form-row">
                  <!-- prettier-ignore -->
                  <input
                    type="text"
                    required
                    :class="['input__text', { 'has-error': errors.researchOrganization }]"
                    :placeholder="`${$t('form.fields.researchOrganization')}*`"
                    @blur="handleFieldValidation('researchOrganization')"
                    @input="handleFieldValidation('researchOrganization')"
                    v-model="fields.researchOrganization"
                  />

                  <p v-if="errors.researchOrganization" class="input__error">
                    {{ errors.researchOrganization }}
                  </p>
                </div>

                <div class="form__form-row">
                  <!-- prettier-ignore -->
                  <input
                    type="text"
                    required
                    :class="['input__text', { 'has-error': errors.organizationProjectRole }]"
                    :placeholder="`${$t('form.fields.organizationProjectRole')}*`"
                    @blur="handleFieldValidation('organizationProjectRole')"
                    @input="handleFieldValidation('organizationProjectRole')"
                    v-model="fields.organizationProjectRole"
                  />

                  <p v-if="errors.organizationProjectRole" class="input__error">
                    {{ errors.organizationProjectRole }}
                  </p>
                </div>

                <div class="form__form-row">
                  <!-- prettier-ignore -->
                  <InputPhone
                    ref="organizationPhone"
                    name="organizationPhone"
                    placeholder="(123) 456-7890*"
                    :error-minimum="$t('form.errors.organizationPhoneMinimum')"
                    :error-invalid="$t('form.errors.organizationPhoneInvalid')"
                    :error-required="$t('form.errors.organizationPhoneRequired')"
                    @custom-validation="handleCustomValidation"
                    v-model="fields.organizationPhone"
                  />

                  <p v-if="errors.organizationPhone" class="input__error">
                    {{ errors.organizationPhone }}
                  </p>
                </div>
              </template>

              <!-- prettier-ignore -->
              <template v-if="interest === 'research' || interest === 'waitlist'">
                <div class="form__form-footer">
                  <input
                    id="form-privacy"
                    type="checkbox"
                    required
                    :class="['input__checkbox', { 'has-error': errors.privacy }]"
                    @change="handleFieldValidation('privacy')"
                    v-model="fields.privacy"
                  />

                  <label for="form-privacy">
                    <span>
                      <span>
                        {{ $t('form.privacy1') }}
                        <NuxtLink target="_blank" to="/privacy-policy">
                          {{ $t('form.privacy2') }}
                        </NuxtLink>
                        {{ $t('form.privacy3') }}
                      </span>

                      <span>
                        {{ $t('form.privacy4') }}
                      </span>
                    </span>
                  </label>

                  <p v-if="errors.privacy" class="input__error">
                    {{ errors.privacy }}
                  </p>
                </div>
              </template>
            </div>
          </template>

          <template v-if="interest === 'research'">
            <template v-if="screen === 3">
              <!-- Step 4 -->
              <div class="form__form">
                <h3 class="form__form-title text--gradient">
                  {{ $t('form.headline4') }}
                </h3>

                <div class="form__form-row">
                  <input
                    type="text"
                    required
                    :class="[
                      'input__text',
                      { 'has-error': errors.researchField },
                    ]"
                    :placeholder="`${$t('form.fields.researchField')}*`"
                    @blur="handleFieldValidation('researchField')"
                    @input="handleFieldValidation('researchField')"
                    v-model="fields.researchField"
                  />

                  <p v-if="errors.researchField" class="input__error">
                    {{ errors.researchField }}
                  </p>
                </div>

                <div class="form__form-row">
                  <InputSelect
                    class="input__select"
                    :class="{ 'has-error': errors.payload }"
                    :label="`${$t('form.fields.payload')}*`"
                    :options="payloadOptions"
                    required
                    @custom-blur="handleFieldValidation('payload')"
                    @custom-change="handleFieldValidation('payload')"
                    v-model="fields.payload"
                  />

                  <p v-if="errors.payload" class="input__error">
                    {{ errors.payload }}
                  </p>
                </div>

                <div class="form__form-row">
                  <InputSelect
                    class="input__select"
                    :class="{ 'has-error': errors.flightAvailability }"
                    :label="`${$t('form.fields.flightAvailability')}*`"
                    :options="flightAvailabilityOptions"
                    required
                    @custom-blur="handleFieldValidation('flightAvailability')"
                    @custom-change="handleFieldValidation('flightAvailability')"
                    v-model="fields.flightAvailability"
                  />

                  <p v-if="errors.flightAvailability" class="input__error">
                    {{ errors.flightAvailability }}
                  </p>
                </div>

                <div class="form__form-row">
                  <InputSelect
                    class="input__select"
                    :class="{ 'has-error': errors.spaceExperience }"
                    :label="`${$t('form.fields.spaceExperience')}*`"
                    :options="spaceExperienceOptions"
                    required
                    @custom-blur="handleFieldValidation('spaceExperience')"
                    @custom-change="handleFieldValidation('spaceExperience')"
                    v-model="fields.spaceExperience"
                  />

                  <p v-if="errors.spaceExperience" class="input__error">
                    {{ errors.spaceExperience }}
                  </p>
                </div>
              </div>
            </template>

            <template v-if="screen === 4">
              <!-- Step 5 -->
              <div class="form__form">
                <h3 class="form__form-title text--gradient">
                  {{ $t('form.headline4') }}
                </h3>

                <div class="form__form-row">
                  <!-- prettier-ignore -->
                  <textarea
                    :class="['input__textarea', { 'has-error': errors.researchDescription }]"
                    :required="researchDescriptionRequired"
                    :placeholder="researchDescriptionRequired ? `${$t('form.fields.researchDescription')}*` : $t('form.fields.researchDescription')"
                    @blur="handleFieldValidation('researchDescription')"
                    @input="handleFieldValidation('researchDescription')"
                    v-model="fields.researchDescription"
                  />

                  <p v-if="errors.researchDescription" class="input__error">
                    {{ errors.researchDescription }}
                  </p>
                </div>
              </div>
            </template>
          </template>

          <div class="form__actions">
            <ButtonPill
              v-if="isSubmitting"
              icon="chevron-right"
              :big="true"
              :animate="true"
              :useGradient="true"
              @click="handleNext"
            >
              {{ $t('form.submitting') }}
            </ButtonPill>

            <ButtonPill
              v-else
              icon="chevron-right"
              :big="true"
              :animate="true"
              :useGradient="true"
              @click="handleNext"
            >
              {{ $t('form.next') }}
            </ButtonPill>

            <button
              v-if="screen !== 0"
              type="button"
              class="form__previous"
              @click="handlePrevious"
            >
              {{ $t('form.previous') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="form__confirmation"
      :class="`form__confirmation--${interest}`"
    >
      <div class="form__confirmation-wrapper">
        <div class="form__confirmation-texts">
          <p class="text__eyebrow">
            <span>{{ $t('form.thanks') }}</span>
            <span v-if="fields.firstName">, {{ fields.firstName }}</span>
          </p>

          <!-- prettier-ignore -->
          <p class="form__confirmation-headline text__headline-3 text--gradient">
            <template v-if="interest === 'research'">
              {{ $t('form.completeHeadline.research') }}
            </template>
            <template v-else-if="interest === 'waitlist'">
              {{ $t('form.completeHeadline.waitlist') }}
            </template>
            <template v-else-if="interest === 'investors'">
              {{ $t('form.completeHeadline.investors') }}
            </template>
            <template v-else>
              {{ $t('form.completeHeadline.updates') }}
            </template>
          </p>

          <div class="form__confirmation-divider" />

          <p class="form__confirmation-description">
            <template v-if="interest === 'research'">
              {{ $t('form.completeCopy.research') }}
            </template>
            <template v-else-if="interest === 'waitlist'">
              {{ $t('form.completeCopy.waitlist') }}
            </template>
            <template v-else-if="interest === 'investors'">
              {{ $t('form.completeCopy.investors') }}
            </template>
            <template v-else>
              {{ $t('form.completeCopy.updates') }}
            </template>
          </p>

          <ButtonPill
            icon="chevron-right"
            :big="true"
            :animate="true"
            :useGradient="true"
            class="form__confirmation-cta"
            @click="handleFinish"
          >
            {{ $t('form.finish') }}
          </ButtonPill>
        </div>
      </div>
    </div>

    <!-- prettier-ignore -->
    <div :class="['form__disclaimer', { 'is-visible': screenDisclaimer && disclaimer }]">
      <p class="form__disclaimer-text">{{ $t('form.disclaimer') }}</p>

      <ButtonIcon icon="close" show-highlight @click="handleDisclaimer" />
    </div>
  </div>
</template>

<style lang="scss">
@use 'sass:math';

// Vendors
@import 'styles/vendors/sass-mq/mq';

// Utils
@import 'styles/utils/variables';
@import 'styles/utils/functions';
@import 'styles/utils/timing-functions';

.form {
  position: relative;
  z-index: 0;
  background: radial-gradient(
      104.31% 242.2% at 124.65% -26.46%,
      rgba(200, 10, 251, 0.2) 0%,
      rgba(200, 10, 251, 0) 100%
    ),
    radial-gradient(
      64.07% 82.55% at 0.87% 100%,
      rgba(200, 10, 251, 0.3) 0%,
      rgba(200, 10, 251, 0) 100%
    ),
    radial-gradient(
      81.85% 133.76% at 100% 100%,
      rgba(21, 42, 244, 0.26) 0%,
      rgba(21, 42, 244, 0) 100%
    ),
    #17022c; // TODO - Temp
  position: relative;

  &__steps {
    display: flex;
    flex-direction: column;
    height: var(--real-height, 100vh);
    overflow-y: auto;

    @include mq($from: 'l') using($from) {
      flex-direction: row;
    }
  }

  &__left {
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0 56px var(--grid-edge);
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    height: var(--real-height, 100vh);
    position: sticky;
    top: 0;
    left: 0;
    flex-shrink: 0;
    background-image: url('~/assets/images/form/sidebar-cropped.png');

    @include mq($from: 'l') using($from) {
      display: flex;
      width: gs(11, 11, $from);
    }
  }
  &__left::before {
    content: '';
    background: radial-gradient(
        117.57% 117.57% at 50% 50%,
        rgba(17, 9, 32, 0) 0%,
        rgba(17, 9, 32, 0.3) 100%
      ),
      linear-gradient(11.73deg, #0e061e 9.78%, rgba(14, 5, 31, 0) 47.69%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
  }

  // &__left-texts {
  //   @include mq($from: 'l') using($from) {
  //     max-width: 400px;
  //   }
  // }

  // &__left-description {
  //   margin-top: 40px;
  // }

  &__right {
    padding: 108px var(--grid-edge) 0;
    display: flex;
    min-height: var(--real-height, 100vh);

    @include mq($from: 'l') using($from) {
      justify-content: center;
      flex-grow: 1;
      min-height: var(--real-height, 100vh);
    }
  }

  &__right-container {
    margin: auto 0;
    padding-bottom: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: gs(6, 5, 'xxxs');

    @include mq($from: 'l') using($from) {
      // padding-bottom: var(--grid-edge);
      width: gs(6, 5, $from);
    }
  }

  &__progress {
    font-variant-numeric: tabular-nums;
    font-size: 10px;
    font-weight: $font-weight-3G;
    line-height: math.div(14, 10);
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: #5b3c8a;

    @include mq($from: 'l') using($from) {
      line-height: math.div(16, 10);
      letter-spacing: 0.27em;
    }
  }

  &__progress b {
    color: #c80afb;
  }

  &__divider {
    margin: 20px 0 24px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    height: 24px;
    flex-shrink: 0;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      display: block;
      grid-area: 1 / 1;
      justify-self: center;
      align-self: center;
    }

    &::before {
      background: radial-gradient(
        50% 50% at 50% 50%,
        #c80afb 0%,
        rgba(200, 10, 251, 0.63) 31.77%,
        rgba(200, 10, 251, 0.36) 60.94%,
        rgba(200, 10, 251, 0) 99.94%
      );
      width: gs(2, 1, 'xxxs');
      height: 48px;
      opacity: 0.2;

      @include mq($from: 'l') using($from) {
        width: gs(2, 1, $from);
      }
    }

    &::after {
      background: linear-gradient(
        89.94deg,
        rgba(200, 10, 251, 0) -2.15%,
        #c80afb 50.7%,
        rgba(200, 10, 251, 0) 99.85%
      );
      width: gs(2, 3, 'xxxs');
      height: 4px;

      @include mq($from: 'l') using($from) {
        width: gs(2, 3, $from);
      }
    }
  }

  &__form {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include mq($from: 'l') using($from) {
      margin-top: 20px;
    }
  }

  &__form-title {
    font-size: 28px;
    line-height: math.div(34, 28);
    letter-spacing: -0.025em;
    text-align: center;
    text-align: center;
    text-transform: uppercase;

    @include mq($from: 'l') using($from) {
      font-size: 37px;
      line-height: math.div(42, 37);
    }
  }

  &__description {
    margin: 32px 0;
    text-align: center;
  }

  &__form-title + &__form-row,
  &__form-title + &__form-grid {
    margin-top: 32px;

    @include mq($from: 'l') using($from) {
      margin-top: 35px;
    }
  }

  &__form-row {
    width: 100%;
    position: relative;
  }

  &__form-row + &__form-row {
    margin-top: 8px;
  }

  &__form-footer {
    margin-top: 24px;

    @include mq($from: 'l') using($from) {
      margin-top: 20px;
    }
  }

  &__form-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    row-gap: 8px;

    @include mq($from: 'l') using($from) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 8px;
      column-gap: 8px;
      width: gs(8, 7, $from);
    }
  }

  &__actions {
    margin-top: 40px;
    display: flex;
    flex-direction: column;

    @include mq($from: 'l') using($from) {
      margin-top: 50px;
    }
  }

  &__previous {
    font-size: 12px;
    font-weight: $font-weight-4G;
    line-height: math.div(12, 12);
    letter-spacing: 0.24em;
    text-transform: uppercase;
    color: rgba($brand-white, 0.9);
    margin-top: 32px;

    @include mq($from: 'l') using($from) {
      margin-top: 30px;
    }
  }

  &__confirmation {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: var(--real-height, 100vh);
    position: relative;
    overflow-y: auto;
  }

  // &__confirmation--updates &__confirmation-wrapper {
  //   background-image: url('/assets/images/form/sidebar-full.png');
  // }

  // &__confirmation--waitlist &__confirmation-wrapper {
  //   background-image: url('/assets/images/form/waitlist-full.png');
  // }

  // &__confirmation--research &__confirmation-wrapper {
  //   background-image: url('/assets/images/form/research-full.png');
  // }

  // &__confirmation--investors &__confirmation-wrapper {
  //   background-image: url('/assets/images/form/updates-full.png');
  // }

  &__confirmation-wrapper {
    padding: 42px 0;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;

    @include mq($from: 'l') using($from) {
      padding: 100px 0;
    }
  }

  &__confirmation-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    background: #000;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 100%;
    background-position-x: center;
    background-image: url('/assets/images/form/sidebar-full.jpg');
  }

  &__confirmation-wrapper::before {
    content: '';
    background: radial-gradient(
        62.22% 94.81% at 99.13% 100%,
        rgba(200, 10, 251, 0.15) 0%,
        rgba(200, 10, 251, 0) 100%
      ),
      radial-gradient(
        50.05% 50.05% at 50% 100%,
        rgba(21, 42, 244, 0.12) 0%,
        rgba(21, 42, 244, 0) 100%
      ),
      radial-gradient(
        147.7% 39.91% at 56.4% 1.9%,
        rgba(13, 6, 29, 0) 0%,
        #0d061d 100%
      );
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;

    @include mq($from: 'l') using($from) {
      background: radial-gradient(
          62.22% 94.81% at 99.13% 100%,
          rgba(200, 10, 251, 0.15) 0%,
          rgba(200, 10, 251, 0) 100%
        ),
        radial-gradient(
          50.05% 50.05% at 50% 100%,
          rgba(21, 42, 244, 0.22) 0%,
          rgba(21, 42, 244, 0) 100%
        ),
        radial-gradient(
          60.69% 53.26% at 59.41% 9.55%,
          rgba(13, 6, 29, 0) 0%,
          #0d061d 100%
        );
    }
  }

  &__confirmation-texts {
    margin-top: auto;
    padding: 0 var(--grid-edge);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 3;
  }

  &__confirmation-headline {
    margin-top: 24px;

    @include mq($from: 'l') using($from) {
      margin-top: 34px;
      width: gs(8, 9, $from);
    }
  }

  &__confirmation-divider {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    height: 24px;
    flex-shrink: 0;
    overflow: hidden;

    @include mq($from: 'l') using($from) {
      margin-top: 10;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      grid-area: 1 / 1;
      justify-self: center;
      align-self: center;
    }

    &::before {
      background: radial-gradient(
        50% 50% at 50% 50%,
        #c80afb 0%,
        rgba(200, 10, 251, 0.63) 31.77%,
        rgba(200, 10, 251, 0.36) 60.94%,
        rgba(200, 10, 251, 0) 99.94%
      );
      width: gs(2, 1, 'xxxs');
      height: 48px;
      opacity: 0.2;

      @include mq($from: 'l') using($from) {
        width: gs(2, 3, $from);
      }
    }

    &::after {
      background: linear-gradient(
        89.94deg,
        rgba(200, 10, 251, 0) -2.15%,
        #c80afb 50.7%,
        rgba(200, 10, 251, 0) 99.85%
      );
      width: gs(2, 1, 'xxxs');
      height: 4px;

      @include mq($from: 'l') using($from) {
        width: gs(4, 3, $from);
      }
    }
  }

  &__confirmation-description {
    margin-top: 40px;

    @include mq($from: 'l') using($from) {
      margin-top: 30px;
      width: gs(7, 7, $from);
    }
  }

  &__confirmation-cta {
    margin-top: 40px;

    @include mq($from: 'l') using($from) {
      margin-top: 50px;
    }
  }

  &__disclaimer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--grid-edge);
    background-color: $brand-purple;
    transition: transform 0.3s $ease-out-quart;
    transform: translateY(100%);

    @include mq($from: 'l') using ($from) {
      left: gs(11, 11, $from);
    }

    &-text {
      flex: 1;
      color: rgba($brand-white, 0.7);
      font-size: 13px;
      font-weight: $font-weight-2G;
      line-height: math.div(16, 13);
      letter-spacing: -0.12px;
      margin-right: var(--grid-edge);
    }

    &.is-visible {
      transform: translateY(0);
    }
  }
}
</style>
